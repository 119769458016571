import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/turkmer/application', config);
}
const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/turkmer/application/save',formData,{
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
const show = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/turkmer/application/show/'+id);
}
const update = async (id,formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/turkmer/application/update/'+id,formData);
}
export default {
    getAll,
    show,
    update,
    store
}
