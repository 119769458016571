<template>
    <div>
        <multiselect
            v-model="selectedValue"
            :options="programSelectBox"
            @input="handlerInput"
            label="text"
            track-by="value"
            :multiple="false"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
            :placeholder="$t('select')"
            :searchable="true">
                                                                            <span slot="noOptions">
                                                                                {{ $t('no_options') }}
                                                                            </span>
        </multiselect>
    </div>
</template>
<script>
export default {
    props:{
        value:{}
    },
    data(){
        return{
            selectedValue:null,
            programSelectBox: [
                {
                    text:'Course', value: 'course',
                },
                {
                    text: 'Exams',value: 'exams'
                }
            ],
        }
    },
    watch:{
      value:{
          handler(newValue){
              this.selectedValue.value=newValue
          }
      }
    },
    methods:{
        handlerInput(){
          this.$emit('input',this.selectedValue.value)
      }
    }


}
</script>
